@import url(https://fonts.googleapis.com/css2?family=Coming+Soon&family=Open+Sans:wght@300;400;600&display=swap);
.draw-panel {
  height: 100%;
  padding: 1rem;
  max-width: 500px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

/* CANVAS */

.canvas-draw {
  border: 1px solid #736f72;
  margin: 1.5rem 1rem;
  max-width: 500px;
  max-height: 500px;
  min-width: 250px;
  min-height: 250px;
}

/* DRAWING ACTIONS (ie undo, download)*/

.img-utils {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.share-url {
  margin-top: 1rem;
  text-align: center;
}

.brush-tools {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 80%;
  margin: auto auto;
  padding: 0 2rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.size-and-picker {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  grid-gap: 1rem;
  gap: 1rem;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.size-and-picker>.brush-size {
  width: 100%;
  place-self: center stretch;
  text-align: center;
}

/* CONTAINER FOR BUTTON + SWATCHES */

.palette-generator {
  width: 9rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow-wrap: break-word;
}

/* CONTAINER FOR ALL SWATCHES*/

.palette-swatches {
  margin: 0 1.5rem;
  padding: 0.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  /* px for consistency with swatches */
  max-height: 200px;
}

.palette-swatch {
  width: 30px;
  height: 30px;
  /* flex: 1 1 30px; */
  margin: 4px;
  border: 0.2px solid rgb(29, 27, 27);
  border-radius: 4px;
  cursor: pointer;
}

/* MOBILE VIEW */

@media screen and (max-width: 500px) {
  .draw-panel {
    width: 100%;
    padding: 1rem 0;
  }
  .brush-tools {
    -webkit-justify-content: center;
            justify-content: center;
  }
  .palette-generator {
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
  }
  .palette-swatches {
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    max-width: 200px;
    margin: 0;
  }
}
:root {
  /* COLORS */
  --white: #ffffff;
  --purple: #571089;
  --btn: #edeaf5;
  --btn-border: 1px solid #3D00B8;
  --canvas-border: 1px solid #736f72;
  --font-large: 'Coming Soon';
}

body {
  background: url(/static/media/beige-paper.ea4127ea.png) #ffffff;
  background: url(/static/media/beige-paper.ea4127ea.png) var(--white);
  background-blend-mode: multiply;
}

.App {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  padding: 1rem;
  font-family: 'Open Sans', sans-serif;
}

/* NAVBAR ITEMS */

nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  /* gap: 1rem; */
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  /* padding: 0 1rem; */
}

h1 {
  font-size: 3rem;
  font-family: 'Coming Soon';
  font-family: var(--font-large);
  margin: -1rem 0;
}

h1>a {
  color: #571089;
  color: var(--purple);
}

.user-icon {
  border-radius: 50%;
  height: 4rem;
}

.login-items {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0.5rem;
}

/* LOADING COMPONENT */

.loading {
  display: -webkit-flex;
  display: flex;
  margin: auto;
  -webkit-justify-content: center;
          justify-content: center;
  font: 2rem 'Coming Soon';
  font: 2rem var(--font-large);
}

/* MAIN PAGE */

.main-page {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.description {
  background: #ffffff80;
  padding: 1rem 1rem;
  margin: 0 .5rem 0 .5rem;
  border-radius: .5em;
}

.img-history {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row-reverse wrap;
          flex-flow: row-reverse wrap;
  grid-gap: 1rem;
  gap: 1rem;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

/* MOBILE VIEWS */

@media screen and (max-width: 1000px) {
  main {
    padding: 0 -50px;
  }
  .App {
    padding: 1rem 0rem;
  }
}

@media screen and (max-width: 500px) {
  main {
    padding: 0 -50px;
  }
  .App {
    padding: 1rem 0rem;
  }
  .user-icon {
    height: 0;
  }
  nav {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }
  .user-icon {
    height: 0;
  }
}
