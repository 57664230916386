@import url('https://fonts.googleapis.com/css2?family=Coming+Soon&family=Open+Sans:wght@300;400;600&display=swap');
:root {
  /* COLORS */
  --white: #ffffff;
  --purple: #571089;
  --btn: #edeaf5;
  --btn-border: 1px solid #3D00B8;
  --canvas-border: 1px solid #736f72;
  --font-large: 'Coming Soon';
}

body {
  background: url("../assets/beige-paper.png") var(--white);
  background-blend-mode: multiply;
}

.App {
  display: flex;
  flex-flow: column wrap;
  padding: 1rem;
  font-family: 'Open Sans', sans-serif;
}

/* NAVBAR ITEMS */

nav {
  display: flex;
  flex-flow: row wrap;
  /* gap: 1rem; */
  justify-content: space-between;
  align-items: center;
  /* padding: 0 1rem; */
}

h1 {
  font-size: 3rem;
  font-family: var(--font-large);
  margin: -1rem 0;
}

h1>a {
  color: var(--purple);
}

.user-icon {
  border-radius: 50%;
  height: 4rem;
}

.login-items {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
}

/* LOADING COMPONENT */

.loading {
  display: flex;
  margin: auto;
  justify-content: center;
  font: 2rem var(--font-large);
}

/* MAIN PAGE */

.main-page {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.description {
  background: #ffffff80;
  padding: 1rem 1rem;
  margin: 0 .5rem 0 .5rem;
  border-radius: .5em;
}

.img-history {
  display: flex;
  flex-flow: row-reverse wrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

/* MOBILE VIEWS */

@media screen and (max-width: 1000px) {
  main {
    padding: 0 -50px;
  }
  .App {
    padding: 1rem 0rem;
  }
}

@media screen and (max-width: 500px) {
  main {
    padding: 0 -50px;
  }
  .App {
    padding: 1rem 0rem;
  }
  .user-icon {
    height: 0;
  }
  nav {
    justify-content: space-evenly;
  }
  .user-icon {
    height: 0;
  }
}