.draw-panel {
  height: 100%;
  padding: 1rem;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* CANVAS */

.canvas-draw {
  border: 1px solid #736f72;
  margin: 1.5rem 1rem;
  max-width: 500px;
  max-height: 500px;
  min-width: 250px;
  min-height: 250px;
}

/* DRAWING ACTIONS (ie undo, download)*/

.img-utils {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.share-url {
  margin-top: 1rem;
  text-align: center;
}

.brush-tools {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  width: 80%;
  margin: auto auto;
  padding: 0 2rem;
  gap: 1rem;
}

.size-and-picker {
  display: flex;
  flex-flow: column wrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.size-and-picker>.brush-size {
  width: 100%;
  place-self: center stretch;
  text-align: center;
}

/* CONTAINER FOR BUTTON + SWATCHES */

.palette-generator {
  width: 9rem;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
}

/* CONTAINER FOR ALL SWATCHES*/

.palette-swatches {
  margin: 0 1.5rem;
  padding: 0.5rem;
  display: flex;
  flex-flow: column wrap;
  /* px for consistency with swatches */
  max-height: 200px;
}

.palette-swatch {
  width: 30px;
  height: 30px;
  /* flex: 1 1 30px; */
  margin: 4px;
  border: 0.2px solid rgb(29, 27, 27);
  border-radius: 4px;
  cursor: pointer;
}

/* MOBILE VIEW */

@media screen and (max-width: 500px) {
  .draw-panel {
    width: 100%;
    padding: 1rem 0;
  }
  .brush-tools {
    justify-content: center;
  }
  .palette-generator {
    width: 100%;
    align-items: center;
  }
  .palette-swatches {
    flex-flow: row wrap;
    max-width: 200px;
    margin: 0;
  }
}